const Badges = document.getElementsByClassName('act-ressources-card__badge')
const Metas = document.getElementsByClassName('act-ressources-card__meta')

redoSectionsWidth();

function redoSectionsWidth(){
    let BadgesWidth = 0;
    let MetasWidth = 0;
    if(Badges){
        for(let el of Badges){
            el.setAttribute('style','')
        }
        for(let el of Badges){
            if(el.offsetWidth > BadgesWidth){BadgesWidth = el.offsetWidth;}
        }
        BadgesWidth+=2;
        for(let el of Badges){
            el.setAttribute('style','width:'+BadgesWidth+'px;')
        }
    }
    if(Metas){
        for(let el of Metas){
            el.setAttribute('style','')
        }
        for(let el of Metas){
            if(el.offsetWidth > MetasWidth){MetasWidth = el.offsetWidth;}
        }
        MetasWidth+=2;
        for(let el of Metas){
            el.setAttribute('style','width:'+MetasWidth+'px;')
        }
    }
}